import { FormatResponse, type ResponseNormalization } from 'seed-core';

import { useRequest } from '@/composables/useRequest';
import { X_DEVICE_TYPE } from '@/constants/common.const';
import { ApiMethod } from '@/enums/api.enum';
import type { CurrencyInfosRequest } from '@/types/currency/currency-request.type';
import type {
  CurrencyInfoItem,
  CurrencyInfosResponse
} from '@/types/currency/currency-response.type';
import { camelToSnake, generateHeader } from '@/utils/api.util';

// getCurrencyInfos
export const fetchCurrencyExchangeRateApi = async (
  params: CurrencyInfosRequest
): Promise<CurrencyInfoItem[] | undefined> => {
  const data = await useRequest<CurrencyInfoItem[], CurrencyInfosResponse>(
    'shop/v2.0/indie/currency/exchange/rate/list',
    {
      headers: {
        ...generateHeader(
          'X-Nation',
          'X-Lang',
          'X-Client-Lang',
          'X-UUID',
          'caller-id',
          'X-Timezone'
        ),
        'X-Device-Type': X_DEVICE_TYPE
      },
      method: ApiMethod.Get,
      params: camelToSnake(params),
      formatResponse: FormatResponse.Custom
    },
    (
      response: ResponseNormalization<CurrencyInfosResponse>
    ): ResponseNormalization<CurrencyInfoItem[]> => {
      return { data: response.data?.value.list };
    }
  );
  return data.data;
};
