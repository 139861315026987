import { defineStore } from 'pinia';
import { ref } from 'vue';

import { fetchCurrencyExchangeRateApi } from '@/apis/currency.api';
import { type CurrencyCode, LocaleByCurrency } from '@/enums/currency-code.enum';
import type { CurrencyInfoModel } from '@/types/currency/currency-common.type';
import type { CurrencyInfoItem, ProductPriceItem } from '@/types/currency/currency-response.type';

interface CommonObject {
  [key: string]: any;
}

export const useCurrencyStore = defineStore('currencyStore', () => {
  const exchangeRates = ref<CurrencyInfoItem[]>();
  const itemInfos = ref<ProductPriceItem[]>();

  const SHOP_KEY: string = 'indie';

  const collectionToObject = (arr: Array<CommonObject>, key: string, value?: string) =>
    arr.reduce(
      (obj: CommonObject, item: CommonObject) => ({
        ...obj,
        [item[key]]: value ? item[value] : item
      }),
      {}
    );

  // TODO: Old name: retrieveCurrencyInfos
  const fetchCurrencyInfos = async (
    currencyCodes: Array<CurrencyCode>
  ): Promise<CurrencyInfoModel[]> => {
    const result = await fetchCurrencyExchangeRateApi({
      shopKey: SHOP_KEY,
      currencyCodes: currencyCodes.join(',')
    });

    if (result) {
      const infos = collectionToObject(result, 'currencyCode');
      return currencyCodes.map(
        (code: CurrencyCode) =>
          ({
            ...infos[code],
            locale: LocaleByCurrency[code]
          } as CurrencyInfoModel)
      );
    }

    return [];
  };

  return { exchangeRates, itemInfos, fetchCurrencyInfos };
});
