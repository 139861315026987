import { useRequest } from '@/composables/useRequest';
import { API_VERSION, STUDIO_API_URL } from '@/constants/api.const';
import { ApiMethod } from '@/enums/api.enum';
import type { Pagination } from '@/types/common/pagination.type';
import type { ProductSellingRequest } from '@/types/product-selling-price/product-selling-price-request.type';
import type {
  PriceTemplateResponse,
  ProductSellingPriceResponse,
  ProductsSellingPriceResponse
} from '@/types/product-selling-price/product-selling-price-response.type';
import { camelToSnake } from '@/utils/api.util';

const apiUrl = `${STUDIO_API_URL}/${API_VERSION}`;

// https://wiki.smilegate.net/pages/viewpage.action?pageId=454902634
export const fetchProductSellingPriceApi = async (
  productNo: number
): Promise<ProductSellingPriceResponse | undefined> => {
  const { data } = await useRequest<ProductSellingPriceResponse>(
    `${apiUrl}/products/selling-price/${productNo}`,
    {
      showCommonError: false
    }
  );

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=454902632
export const createProductSellingPriceApi = async (
  productNo: number,
  request: ProductSellingRequest
): Promise<ProductSellingPriceResponse | undefined> => {
  const { data } = await useRequest<ProductSellingPriceResponse>(
    `${apiUrl}/products/selling-price/${productNo}`,
    {
      data: camelToSnake(request),
      method: ApiMethod.Post,
      showCommonError: false
    }
  );

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=454902793
export const deleteProductSellingPriceApi = async (
  productNo: number
): Promise<{ success: boolean } | undefined> => {
  const { data } = await useRequest<{ success: boolean }>(
    `${apiUrl}/products/selling-price/${productNo}`,
    {
      method: ApiMethod.Delete
    }
  );

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=454902790
export const modifyProductSellingPriceApi = async (
  productNo: number,
  request: ProductSellingRequest
) => {
  const { data } = await useRequest<ProductSellingPriceResponse>(
    `${apiUrl}/products/selling-price/${productNo}`,
    {
      data: camelToSnake(request),
      method: ApiMethod.Put
    }
  );

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?spaceKey=SDKAPIDOCU&title=WDB03.+studio+%7C+Product+%7C+Price+Template
export const fetchPriceTemplatesApi = async (
  productNo: number
): Promise<Pagination<PriceTemplateResponse[]> | undefined> => {
  const { data } = await useRequest<Pagination<PriceTemplateResponse[]>>(
    `${apiUrl}/price-templates`,
    {
      params: camelToSnake({ productNo }),
      showCommonError: false
    }
  );

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=483120288
export const fetchProductsSellingPriceApi = async (
  productNos: number[]
): Promise<ProductsSellingPriceResponse | undefined> => {
  const { data } = await useRequest<ProductsSellingPriceResponse>(
    `${apiUrl}/products/selling-price`,
    {
      params: camelToSnake({ productNos: productNos.join(',') })
    }
  );

  return data;
};
